import React from 'react';
import { Switch, Route, withRouter, matchPath } from 'react-router-dom';
import reqwest from 'reqwest';
import moment from 'moment';

import Month from './Month';
import Week from './Week';
import settings from '../../../../settings';
import Loader from '../../../components/Loader';
import DataContainer from '../../../logic/dataContainer';
import getConstants from '../../../logic/constants';

class DutyRosterDetail extends React.Component {
  constructor(props) {
    super(props);
    this.paths = {
      week: `${props.match.path}/week/:week?`,
    };

    this.state = {
      loading: true,
      officeData: {},
      weekdays: [],
      holidays: [],
      employees: [],
      openingHours: [],
    };

    const weekdayPromise = getConstants('Weekday').then(weekdays => {
      this.setState({
        weekdays,
      });
    });

    Promise.all([weekdayPromise]).then(() => {
      this.getRosterOverview();
      const matchWeek = matchPath(this.props.location.pathname, this.paths.week);
      if (matchWeek) {
        this.getDayBookings();
      } else {
        this.getMonthBookings();
      }
    });
  }

  componentDidUpdate(prevProps) {
    const prevMatchWeek = matchPath(prevProps.location.pathname, this.paths.week) || { params: { week: null } };
    const matchWeek = matchPath(this.props.location.pathname, this.paths.week);

    if (this.props.match.params.month !== prevProps.match.params.month) {
      this.getRosterOverview();
      this.getMonthBookings();
    } else if (!matchWeek && prevMatchWeek.params.week !== null) {
      // Update bookings on back from week to month view
      this.getMonthBookings();
    }

    if (matchWeek && matchWeek.params.week !== prevMatchWeek.params.week) {
      this.getDayWeekBookings();
    }
  }

  getRosterOverview = () => {
    this.setState({
      loading: true,
    });
    reqwest({
      method: 'GET',
      url: settings.dutySchedule.getRosterOverview,
      data: {
        Token: localStorage.getItem('token'),
        Month: moment(this.props.match.params.month, 'M').format('M'),
        Year: moment(this.props.match.params.year, 'YYYY').format('YYYY'),
        OfficeGuid: this.props.match.params.officeGuid.replace(/\s/g, ''),
      },
    }).then(res => {
      let data = JSON.parse(res);
      if (data) {
        this.rosterContainer = new DataContainer(data);
        const officeData = this.rosterContainer.get('Entry');
        const vacations = this.rosterContainer.getMany('UserRosterData');
        let openingHours = this.rosterContainer.get('OpeningHour', 'OpeningHourItems');
        openingHours = openingHours
          ? openingHours.map(item => ({
              ...item.Params,
              Day: this.state.weekdays.find(day => day.value === item.Params.Day).label,
            }))
          : [];

        const isLocked = officeData && (officeData.OfficeState === 'accepted' || officeData.OfficeState === 'review');
        this.setState({
          officeData,
          vacations,
          openingHours,
          loading: false,
          isLocked,
        });
      }
    });
  };

  getDayWeekBookings = day => {
    if (day) {
      const date = moment(day, 'DD.MM.YYYY').format('DD.MM.YYYY');
      this.setState(prevState => {
        const bookings = { ...prevState.bookings };
        bookings[date] = null;
        return {
          bookingsLoading: true,
          bookings,
        };
      });
      reqwest({
        method: 'GET',
        url: settings.dutySchedule.bookings.getRange,
        data: {
          Token: localStorage.getItem('token'),
          From: date,
          To: date,
        },
      }).then(data => {
        this.setState(prevState => {
          const bookings = { ...prevState.bookings };
          bookings[date] = new DataContainer(data);
          return {
            bookingsLoading: false,
            bookings,
          };
        });
      });
      return;
    }

    // Weekly View
    const match = matchPath(this.props.location.pathname, this.paths.week);
    if (match) {
      const currentWeek = moment(match.params.week, 'w');

      const startDay = currentWeek.clone().startOf('week').format('DD.MM.YYYY');
      const endDay = currentWeek.clone().endOf('week').format('DD.MM.YYYY');

      this.setState({
        bookingsLoading: true,
      });

      reqwest({
        method: 'GET',
        url: settings.dutySchedule.bookings.getRange,
        data: {
          Token: localStorage.getItem('token'),
          From: startDay,
          To: endDay,
          Month: currentWeek.format('MM'),
        },
      }).then(data => {
        const container = new DataContainer(data);
        const bookingsContainer = new DataContainer(container.get('Bookings').Bookings);
        const employeesContainer = new DataContainer(container.get('Employees').Employee);

        const employees = employeesContainer.getMany('Mitarbeiter');
        const bookings = bookingsContainer.getMany('Booking');

        this.setState({
          bookings,
          employees,
          bookingsLoading: false,
        });
      });
    }
  };

  getMonthBookings = () => {
    if (this.props.match.params.month && this.props.match.params.year) {
      const currentMonth = moment(`${this.props.match.params.month}.${this.props.match.params.year}`, 'M.YYYY');
      const startDay = currentMonth.clone().startOf('month').format('DD.MM.YYYY');
      const endDay = currentMonth.clone().endOf('month').format('DD.MM.YYYY');

      this.setState({
        bookingsLoading: true,
      });

      reqwest({
        method: 'GET',
        url: settings.dutySchedule.bookings.getRange,
        data: {
          Token: localStorage.getItem('token'),
          From: startDay,
          To: endDay,
          Month: currentMonth.format('MM'),
        },
      }).then(data => {
        const container = new DataContainer(data);
        const bookingsContainer = new DataContainer(container.get('Bookings').Bookings);
        const employeesContainer = new DataContainer(container.get('Employees').Employee);

        const employees = employeesContainer.getMany('Mitarbeiter');
        const bookings = bookingsContainer.getMany('Booking');

        this.setState({
          bookingsMonth: bookings,
          employeesMonth: employees,
          bookingsLoading: false,
        });
      });
      return;
    }
  };

  render() {
    if (this.state.loading) {
      return <Loader />;
    }

    return (
      <Switch>
        <Route
          path={this.paths.week}
          render={routerProps => (
            <Week {...routerProps} {...this.state} getRosterOverview={this.getRosterOverview} getDayBookings={this.getDayWeekBookings} />
          )}
        />
        <Route
          render={routerProps => (
            <Month {...routerProps} {...this.state} getRosterOverview={this.getRosterOverview} getDayBookings={this.getMonthBookings} />
          )}
        />
      </Switch>
    );
  }
}

export default withRouter(DutyRosterDetail);
